const axios = require('axios')

const baseUrl = "https://hashmi.site:5000"
// const baseUrl = "http://localhost:5000"
const hashmiDomain = "hashmi"
const aalyManzarDomain = "aalymanzar"

function callAPI(method, url, data) {
    let subdomain;
    let splitUrl = window.location.host.split(".");
    if (splitUrl[0].indexOf("localhost") !== -1) {
        subdomain = "demo";
    } else {
        splitUrl.forEach((str, i) => {
            if (str.indexOf("localhost") !== -1 || str.localeCompare(hashmiDomain) === 0 || str.localeCompare(aalyManzarDomain) === 0) {
                subdomain = splitUrl[i - 1];
            }
        })
    }
    url += `?subdomain=${subdomain}`
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: baseUrl + url,
            data: data
        }).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err)
        })
    })
}

module.exports = {
    callAPI
}
