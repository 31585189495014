import React, { Component } from 'react';
import '../css/LoadingPage.css';

export default class LoadingPage extends Component {
    render() {
        return (
            <div id="loadingPage">
                <div id="background"></div>
                <div id="loader"></div>
            </div>
        );
    }
}