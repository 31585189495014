
const bismillah = require("../res/bismillah.png");
const bismillah1 = require("../res/basmallah1.png");
const bismillah2 = require("../res/basmallah2.png");
const bismillah3 = require("../res/basmallah3.png");
const bismillah6 = require("../res/basmallah6.png");
const bismillah7 = require("../res/basmallah7.png");
const arrow = require("../res/arrow.png");
const arrow3 = require("../res/arrow3.png");

let retrievedTheme = false;
let isThemed;
let primary;
let secondary;
let textColor;
let headerBasmallah;
let arrowToUse;

function retrieveTheme() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    const data = JSON.parse(sessionStorage.getItem("data"));

    // We only check the one value and assume everything else is set
    if (queryParams.imagePrimaryColor) {
        primary = queryParams.imagePrimaryColor;
        secondary = queryParams.imageSecondaryColor;
        textColor = queryParams.textColor;    
        isThemed = true;
    } else if (data["theme"]) {
        primary = data["theme"]["primary"]
        secondary = data["theme"]["secondary"]
        textColor = data["theme"]["textColor"]
        isThemed = true;
    } else {
        textColor = getComputedStyle(document.documentElement).getPropertyValue("--theme-color");
    }
    
    headerBasmallah = (isThemed) ? bismillah7 : bismillah
    arrowToUse = (isThemed) ? arrow3 : arrow

    headerBasmallah = getCorrectImageFormat(headerBasmallah)
    arrowToUse = getCorrectImageFormat(arrowToUse)

    document.documentElement.style.setProperty("--theme-color", textColor);

    retrievedTheme = true;
}

function getTheme() {
    if (!retrievedTheme) retrieveTheme();

    return {
        isThemed: isThemed, primary: primary, secondary: secondary, textColor: textColor, headerBasmallah: headerBasmallah, arrow: arrowToUse
    }
}

function getCorrectImageFormat(image) {
    // For some reason, server imports image as module instead of image itself, so we need to access the "default" property on the import to display the image
    if (image.default) {
        return image.default;
    }
    return image;
}

function getArrowStyle() {
    return isThemed ? {"backgroundImage": `radial-gradient(circle, ${secondary}, ${primary})`} : {}
}

module.exports = {
    getTheme,
    getArrowStyle
}
