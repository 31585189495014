import React, { Component } from 'react';
import ThemeProvider from '../Utilities/theme_provider';
import '../css/Header.css';


export default class Header extends Component {
    data = JSON.parse(sessionStorage.getItem("data"));

    render() {
        const {isThemed, primary, secondary, _textColor, headerBasmallah, arrow} = ThemeProvider.getTheme();

        function getStyle() {
            return isThemed ? {"backgroundImage": `linear-gradient(to right, ${primary}, ${secondary}, ${primary})`} : {}
        }

        return (
            <div id="header">
                <img
                    id="bismillah"
                    src={headerBasmallah}
                    style={getStyle()}
                    alt="Bismillah"
                />
                <h1 id="couple" className="cursive" style={{"color": "var(--theme-color)"}}>{this.data.couple.groom} & {this.data.couple.bride}'s Wedding</h1>
                <p id="invitedBy">Invited by {this.data.parents}</p>
            </div>
        );
    }
}
